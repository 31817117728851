import { CrowdToken } from '@crowdswap/constant';

export class CurrentNetwork {
  private _chainId: number;
  private _defaultFromToken: CrowdToken | undefined;
  private _defaultToToken: CrowdToken | undefined;

  constructor(
    chainId: number,
    defaultFromToken?: CrowdToken,
    defaultToToken?: CrowdToken
  ) {
    this._chainId = chainId;
    this._defaultFromToken = defaultFromToken;
    this._defaultToToken = defaultToToken;
  }

  get chainId(): number {
    return this._chainId;
  }

  set chainId(value: number) {
    this._chainId = value;
  }

  get defaultFromToken(): CrowdToken | undefined {
    return this._defaultFromToken;
  }

  set defaultFromToken(value: CrowdToken | undefined) {
    this._defaultFromToken = value;
  }

  get defaultToToken(): CrowdToken | undefined {
    return this._defaultToToken;
  }

  set defaultToToken(value: CrowdToken | undefined) {
    this._defaultToToken = value;
  }
}
