import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor() {}

  logError(message: string) {
    console.error(message);
  }

  logWarn(message: string) {
    console.warn(message);
  }

  log(message: string) {
    console.log(message);
  }

  logDebug(message: string) {
    console.debug(message);
  }

  logInfo(message: string) {
    console.info(message);
  }

  logTrace(message: string) {
    console.trace(message);
  }

  error = this.logError;

  warn = this.logWarn;

  debug = this.logDebug;

  info = this.logInfo;

  trace = this.logTrace;
}
