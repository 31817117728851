import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Web3Service } from './web3.service';

const baseUrl = environment.PORTFOLIO_BASEURL || '';

@Injectable()
export class HistoryService {
  public userStatus;

  constructor(private web3Service: Web3Service, private http: HttpClient) {
    // Listen to account change
    web3Service.accountChangeSubject.subscribe(async (address) => {
      if (!this.web3Service.isConnected()) {
        return;
      }
    });
  }

  public async getTransactionHistory(
    address: string,
    limit: number,
    page: number
  ): Promise<any> {
    try {
      let history: any = [];
      const url = `${baseUrl}/api/v1/history/transaction-history`;

      const data = {
        walletAddress: address,
        limit: limit,
        page: page
      };
      history = await this.http
        .get(url, {
          params: data
        })
        .toPromise();
      return history;
    } catch (err: any) {
      console.log(`Cannot fetch user transaction history`);
    }
  }
}
