import { Pipe, PipeTransform } from '@angular/core';
import { Web3Service } from 'src/app/services';

@Pipe({
  name: 'ChainName'
})
export class ChainNamePipe implements PipeTransform {
  constructor(private web3Service: Web3Service) {}

  transform(
    selectedChainIds: string,
    exclusionChainIds?: string
  ): string | undefined {
    if (!selectedChainIds) return undefined;

    const chainIds = selectedChainIds.split(',');
    let chainNames: string | undefined;

    chainIds.map((chain) => {
      if (!exclusionChainIds?.split(',').includes(chain)) {
        chainNames = chainNames
          ? [chainNames, this.web3Service.networkSpec[chain]?.title].join(',')
          : this.web3Service.networkSpec[chain]?.title;
      }
    });

    return chainNames;
  }
}
