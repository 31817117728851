import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ConvertToTitleCase'
})
export class ConvertToTitleCasePipe implements PipeTransform {
  transform(data: string): string {
    if (!data) return ''; // Handle empty or null strings

    // Convert the entire string to lowercase
    const lowerCaseString = data.toLowerCase();

    // Capitalize the first letter and concatenate with the rest of the string
    const capitalized =
      lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);

    return capitalized.replace(/\_/g, ' ');
  }
}
