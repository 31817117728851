import { BigNumber } from 'ethers';
import { Token } from '@crowdswap/sdk';
import {
  Conversion,
  CrowdToken,
  Dexchanges,
  Networks,
  TokensHolder
} from '@crowdswap/constant';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { filter, throttleTime } from 'rxjs/operators';
import { CurrentNetwork } from '../../model/current-network';
import { asyncScheduler, Subscription } from 'rxjs';
import { Constants } from '../../constants';
import {
  DeviceType,
  NDDClientInfoServiceImpl,
  ThemeService,
  Web3Service
} from '../../services';
import { environment } from '../../../environments/environment';
import {
  CrosschainStatus,
  IScannerTokenTransfer,
  IFee,
  ITxScanner
} from 'src/app/model';
import { NumberType, formatNumber } from '@uniswap/conedison/format.js';

@Component({
  template: ''
})
export abstract class BaseComponent implements OnInit, OnDestroy {
  public highPriceImpactPercent = environment.HIGH_PRICE_IMPACT_PERCENT;
  public warningPriceImpactPercent = environment.WARNING_PRICE_IMPACT_PERCENT;
  public setting: {
    zeroAddress: string;
    isDarkMode: boolean;
    baseLogoIconsUrl: string;
    isMobile: boolean;
    showMaxTooltip?: boolean;
  } = {
    zeroAddress: '0x0000000000000000000000000000000000000000',
    isDarkMode: false,
    baseLogoIconsUrl: environment.BaseLogoIconsUrl,
    isMobile: false,
    showMaxTooltip: false
  };

  public status: {
    chainId: number;
    supportedNetwork: boolean;
    walletAddress: string;
    isUserWalletConnected: boolean;
    isWrongNetwork: boolean;
    incorrectNetworkMessage: string;
  } = {
    chainId: -1,
    supportedNetwork: false,
    walletAddress: '',
    isUserWalletConnected: false,
    isWrongNetwork: false,
    incorrectNetworkMessage: ''
  };

  protected subscriptionList: Subscription[] = [];
  protected isSplach = false;

  public get zeroAddress() {
    return this.setting.zeroAddress;
  }

  public get isDarkMode() {
    return this.setting.isDarkMode;
  }

  public set isDarkMode(_isDarkMode: boolean) {
    this.setting.isDarkMode = _isDarkMode;
  }

  public get baseLogoIconsUrl() {
    return this.setting.baseLogoIconsUrl;
  }

  public get chainId() {
    return this.status.chainId;
  }

  public set chainId(_chainId: number) {
    this.status.chainId = _chainId;
  }

  public get supportedNetwork() {
    return this.status.supportedNetwork;
  }

  public get walletAddress() {
    return this.status.walletAddress;
  }

  public set walletAddress(_walletAddress: string) {
    this.status.walletAddress = _walletAddress;
  }

  public get isUserWalletConnected() {
    return this.status.isUserWalletConnected;
  }

  public get isWrongNetwork() {
    return this.status.isWrongNetwork;
  }

  public set isWrongNetwork(_isWrongNetwork: boolean) {
    this.status.isWrongNetwork = _isWrongNetwork;
  }

  public get incorrectNetworkMessage() {
    return this.status.incorrectNetworkMessage;
  }

  public set incorrectNetworkMessage(_incorrectNetworkMessage: string) {
    this.status.incorrectNetworkMessage = _incorrectNetworkMessage;
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.checkDevice(width);
  }

  protected constructor(
    protected web3Service: Web3Service,
    protected themeService: ThemeService,
    protected clientInfoServiceImpl?: NDDClientInfoServiceImpl
  ) {
    this.checkDevice(window.innerWidth);

    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      this.setting.isDarkMode = isDark;
    });
  }

  public checkDevice(width: number) {
    if (this.clientInfoServiceImpl) {
      const deviceType = this.clientInfoServiceImpl.getDeviceType();

      this.setting.isMobile =
        [DeviceType.MOBILE, DeviceType.TABLET].indexOf(deviceType) > -1 ||
        (width && width < 1024 ? true : false);
    }
  }

  public ngOnInit(
    onCurrentNetworkChange:
      | ((currentNetwork: any) => void)
      | undefined = undefined,
    onWalletConnectionChange:
      | ((connection: any) => void)
      | undefined = undefined,
    onAccountChange: ((address: any) => Promise<void>) | undefined = undefined
  ): void {
    this.status.isUserWalletConnected = this.web3Service.isConnected() || false;
    this.status.walletAddress = this.web3Service.getWalletAddress() || '';

    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.status.supportedNetwork = Dexchanges.Crowdswap.networks[
            currentNetwork.chainId
          ]
            ? true
            : false;
          this.status.chainId = currentNetwork.chainId;

          this.executeAction(onCurrentNetworkChange, currentNetwork);
        })
    );

    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        async (connection) => {
          this.status.isUserWalletConnected = connection;
          this.executeAction(onWalletConnectionChange, connection);
        }
      )
    );

    //The selected network in the wallet is not supported by our app
    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        (isWrongNetwork: boolean) => {
          this.status.isWrongNetwork = isWrongNetwork;
          if (this.status.isWrongNetwork) {
            this.status.incorrectNetworkMessage = 'Wrong network';
          }
        }
      )
    );

    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async (address) => {
        this.status.walletAddress = address;
        this.executeAction(onAccountChange, address);
      })
    );
  }

  public static _getDefaultTokens(fromChainId: number): CrowdToken[] {
    let toChainId: number = fromChainId;

    let _fromToken =
      TokensHolder.ObservableTokenListBySymbol[Networks[fromChainId]][
        Constants.DEFAULT_PAIRS[fromChainId].fromToken
      ];
    let _toToken =
      TokensHolder.ObservableTokenListBySymbol[Networks[toChainId]][
        Constants.DEFAULT_PAIRS[toChainId].toToken
      ];

    return [_fromToken, _toToken];
  }

  public async connectWallet() {
    if (this.web3Service.isConnected()) {
      return;
    }
    await this.web3Service.openModal().catch(console.log);
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  //Remove duplicate
  public static removeDuplicateTokens(
    observableTokens: any,
    localStoredTokens: CrowdToken[]
  ): CrowdToken[] {
    const allTokens: CrowdToken[] = Object.values(observableTokens);
    localStoredTokens.forEach((localStoredToken) => {
      if (!localStoredToken.symbol) {
        return;
      }
      const foundToken = observableTokens[localStoredToken.symbol];
      if (!foundToken || foundToken.chainId !== localStoredToken.chainId) {
        allTokens.push(localStoredToken);
      }
    });
    return allTokens.sort(
      (token0, token1) =>
        parseFloat(token1.balance || '0') - parseFloat(token0.balance || '0')
    );
  }

  public updateUrl(el: any) {
    el.target.src =
      'https://raw.githubusercontent.com/Crowdswap/assets/master/icons/00-none.png';
  }

  public scrollTopContentSection(toInvestmentList?: boolean) {
    const contentSection = document.querySelector('.content-section');
    const investmentList = document.getElementById('investmentList');

    contentSection?.scrollTo({
      top:
        toInvestmentList && investmentList ? investmentList.offsetTop - 80 : 0,
      behavior: 'smooth'
    });
  }

  protected isCoin(token: Token): boolean {
    return TokensHolder.isBaseToken(token.chainId, token.address);
  }

  protected async checkIfAllowanceIsNeeded(
    token: Token,
    spender: string,
    amount: string,
    userAddress: string | undefined
  ) {
    try {
      if (userAddress) {
        if (!this.isCoin(token)) {
          let allowance: BigNumber = await this.web3Service
            .getAllowanceBySpender(spender, token.address, userAddress)
            .catch((e) => {
              console.log(e);
              return;
            });
          let allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
            allowance.toString()
          );
          let amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
            Conversion.convertStringToDecimal(amount, token.decimals).toString()
          );
          if (allowanceValue.lt(amountInValue)) {
            return true;
          }
        }
      }
    } catch (e) {
      console.log(e);
      return true;
    }
    return false;
  }

  protected async changeNetworkTo(newChainId: number) {
    if (!this.status.isUserWalletConnected) {
      return;
    }
    if (newChainId !== this.web3Service.getWalletChainId()) {
      await this.web3Service.changeNetwork(newChainId);
    }
  }

  private executeAction(
    action: ((param: any) => void) | undefined,
    param: any
  ) {
    if (action && typeof action === 'function') {
      try {
        action(param);
      } catch (err) {
        console.log(err);
      }
    }
  }

  public substringAddress(
    address?: string,
    from?: number,
    to?: number
  ): string {
    if (!address) {
      return '';
    }

    const length = address.length;

    return `${address.substring(0, from ?? 7)}...${address.substring(
      length - (to ?? 7),
      length
    )}`;
  }

  public changeDarkModeStatus() {
    if (localStorage.getItem('darkMode') === 'true') {
      localStorage.setItem('darkMode', 'false');
      this.isDarkMode = false;
      document.body.classList.remove('dark');
    } else {
      localStorage.setItem('darkMode', 'true');
      this.isDarkMode = true;
      document.body.classList.add('dark');
    }

    this.themeService.darkModeChangeSubject.next(this.isDarkMode);
  }

  public encodeQueryData(data?: {}): string {
    if (!data) {
      return '';
    }

    const ret: string[] = [];
    for (const d in data) {
      if (Object.hasOwn(data, d) && data[d] != null) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return (data ? '?' : null) + ret.join('&');
  }

  public calculateTotalFee(data: ITxScanner) {
    if (!data) {
      return;
    }

    const feeToDisplayList: IFee[] = [];
    data.fees?.forEach((f) => {
      // Skip the fee calculation if the step is 'variableFee'
      if (f.step === 'variableFee') {
        return;
      }

      const lastFeeIndex = feeToDisplayList.findIndex(
        (x) =>
          x.token.chainId === f.token.chainId &&
          x.token.symbol === f.token.symbol
      );

      if (lastFeeIndex > -1) {
        feeToDisplayList[lastFeeIndex].amount = (
          parseFloat(feeToDisplayList[lastFeeIndex].amount) +
          parseFloat(Conversion.adjustFraction(f.amount, 7))
        ).toString();

        if (feeToDisplayList[lastFeeIndex].amountInUSDT) {
          feeToDisplayList[lastFeeIndex].amountInUSDT =
            (feeToDisplayList[lastFeeIndex].amountInUSDT ?? 0) +
            parseFloat(f.amount) * parseFloat(f.token.price ?? 0);
        }
      } else {
        const amountToDisplay = formatNumber(
          parseFloat(f.amount),
          NumberType.SwapTradeAmount
        );

        const amountInUSDT =
          parseFloat(f.amount) * parseFloat(f.token.price ?? 0);

        feeToDisplayList.push({
          amount: f.amount,
          amountToDisplay: amountToDisplay,
          token: f.token,
          amountInUSDT: amountInUSDT,
          amountInUSDTToDisplay: formatNumber(
            amountInUSDT,
            NumberType.FiatTokenPrice
          )
        });
      }
    });

    let totalInUsdt = 0;
    data.totalFee = '';

    feeToDisplayList.forEach((fee) => {
      if (fee.amount && parseFloat(fee.amount) > 0 && data) {
        totalInUsdt += fee.amountInUSDT ?? 0;
        data.totalFee += `${data.totalFee ? ' + ' : ''}${fee.amountToDisplay} ${fee.token.symbol}`;
      }
    });

    data.totalFee +=
      totalInUsdt > 0
        ? ` ≃ ${formatNumber(totalInUsdt, NumberType.PortfolioBalance)}`
        : '$0.00';
  }

  public initTokenTransfers(data: ITxScanner) {
    if (!data) {
      return;
    }
    data.token_transfer.forEach((x) => {
      switch (x.step) {
        case CrosschainStatus.initiated:
          x.amountToDisplay = Number(x.amount).toLocaleString('fullwide', {
            useGrouping: false,
            maximumSignificantDigits: 6
          });
          x.amountInUSDT =
            parseFloat(x.amount) * parseFloat(x.token.price ?? 0);
          x.amountInUSDTToDisplay = formatNumber(
            x.amountInUSDT,
            NumberType.FiatTokenPrice
          );

          data.sourceTokenTransfer = x;
          break;

        case CrosschainStatus.executed:
          x.amountToDisplay = Number(x.amount).toLocaleString('fullwide', {
            useGrouping: false,
            maximumSignificantDigits: 6
          });
          x.amountInUSDT =
            parseFloat(x.amount) * parseFloat(x.token.price ?? 0);
          x.amountInUSDTToDisplay = formatNumber(
            x.amountInUSDT,
            NumberType.FiatTokenPrice
          );

          data.destTokenTransfer = x;
          break;
      }
    });
  }

  public showSplash() {
    document.getElementById('splash-div')!.style.display = 'flex';
  }

  public closeSplash() {
    if (!this.isSplach) {
      document.getElementById('splash-div')!.style.display = 'none';
    }
  }
}
