import { Injectable } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { IClientInfoServiceService } from '../IClientInfoService.service';
import { BrowserType } from './enum/BrowserType';
import { OsType } from './enum/OsType';
import { DeviceType } from './enum/DeviceType';

@Injectable({
  providedIn: 'root'
})
export class NDDClientInfoServiceImpl implements IClientInfoServiceService {
  constructor(private deviceService: DeviceDetectorService) {}

  getBrowserType(): BrowserType {
    let browserName = this.deviceService.browser;
    return this._getBrowserTypeFromBrowserName(browserName);
  }

  getDeviceType(): DeviceType {
    let deviceType: DeviceType = DeviceType.UNKNOWN;
    if (this.deviceService.isDesktop()) {
      deviceType = DeviceType.DESKTOP;
    } else if (this.deviceService.isTablet()) {
      deviceType = DeviceType.TABLET;
    } else if (this.deviceService.isMobile()) {
      deviceType = DeviceType.MOBILE;
    } else {
      deviceType = DeviceType.UNKNOWN;
    }
    return deviceType;
  }

  getOsType(): OsType {
    let osName = this.deviceService.os;
    return this._getOsTypeFromOsName(osName);
  }

  _getOsTypeFromOsName(osName: string): OsType {
    let osType: OsType = OsType.UNKNOWN;
    if (osName.match(/Windows/gi)) {
      osType = OsType.WINDOWS;
    } else if (osName.match(/Linux/gi)) {
      osType = OsType.LINUX;
    } else if (osName.match(/Mac/gi)) {
      osType = OsType.MAC;
    } else if (osName.match(/Android/gi)) {
      osType = OsType.ANDROID;
    } else if (osName.match(/Ios/gi)) {
      osType = OsType.IOS;
    } else {
      osType = OsType.UNKNOWN;
    }

    return osType;
  }

  _getBrowserTypeFromBrowserName(browserName: string): BrowserType {
    let browserType: BrowserType = BrowserType.UNKNOWN;
    if (browserName.match(/Chrome/gi)) {
      browserType = BrowserType.CHROME;
    } else if (browserName.match(/Firefox/gi)) {
      browserType = BrowserType.FIREFOX;
    } else if (browserName.match(/Safari/gi)) {
      browserType = BrowserType.SAFARI;
    } else if (browserName.match(/Opr/gi)) {
      browserType = BrowserType.OPERA;
    } else if (browserName.match(/IE/gi)) {
      browserType = BrowserType.IE;
    } else if (browserName.match(/Edge/gi)) {
      browserType = BrowserType.EDGE;
    } else {
      browserType = BrowserType.UNKNOWN;
    }

    return browserType;
  }
}
