import { IFee } from './cross-chain-estimate-trade.model';
import { CrowdToken } from '@crowdswap/constant';

export interface ITxScanner {
  _id: string;
  create_at: number;
  crowdswap_chain: IScannerCrowdswapChain;
  destination_approve: IScannerSource;
  destination_execute: IScannerSource;
  source_cancel?: IScannerSource;
  executing_at: null;
  gas_payed: ITxScannerGasPayed[];
  order_id: string;
  source: IScannerSource;
  status: CrosschainStatus;
  failed_status: boolean;
  token_transfer: IScannerTokenTransfer[];
  fees: ITxScannerGasPayed[];

  _idToDisplay: string;
  statusCode: CrosschainStatusCode;
  totalFee?: string;
  remaining?: string;
  sourceTokenTransfer?: IScannerTokenTransfer | undefined;
  destTokenTransfer?: IScannerTokenTransfer | undefined;
}

export interface IScannerReceiptLog {
  blockHash: string;
  address: string;
  logIndex: number;
  data: string;
  topics: string[];
  blockNumber: number;
  transactionHash: string;
}

export interface IScannerReceipt {
  to: string;
  from: string;
  logs: IScannerReceiptLog[];
  contractAddress: string;
  transactionIndex: number;
  gasUsed: string;
  logsBloom: string;
  blockHash: string;
  blockNumber: number;
  confirmations: number;
  cumulativeGasUsed: string;
  effectiveGasPrice: string;
  type: number;
  status: number;

  fromToDisplay?: string;
  fromLink?: string;
  toToDisplay?: string;
  toLink?: string;
}

export interface IScannerSource {
  chain_id: number;
  chain_name: string;
  middle_contract_address: string;
  gateway_address: string;
  block_number: number;
  timestamp: number;
  tx_hash: string;
  method: string;
  chain_type: string;
  destination_chain_type: string;
  data: {
    sender: string;
    destinationChain: string;
    destinationContractAddress: string;
    payloadHash: string;
    payload: string;
    _id: string;

    destinationContractAddressLink: string;
    payloadToDisplay?: string;
    payloadHashToDisplay?: string;
    destinationContractAddressToDisplay?: string;
    senderToDisplay?: string;
  };
  receipt: IScannerReceipt;

  middle_contract_addressToDisplay?: string;
  middle_contract_addressLink?: string;
  gateway_addressToDisplay?: string;
  gateway_addressLink?: string;
  tx_hashToDisplay?: string;
  tx_hashLink?: string;
}

export interface IScannerTokenTransfer {
  token: CrowdToken;
  step: CrosschainStatus;
  amount: string;
  from: string;
  to: string;

  amountToDisplay?: string;
  amountInUSDT?: number;
  amountInUSDTToDisplay?: string;
}

export interface ITxScannerGasPayed extends Omit<IFee, 'step'> {
  step: CrosschainStatus | 'variableFee';
}

export type ITxScannerGasPayedToDisplay = {
  initiated?: ITxScannerGasPayed;
  confirmed?: ITxScannerGasPayed;
  approved?: ITxScannerGasPayed;
  executed?: ITxScannerGasPayed;
  canceled?: ITxScannerGasPayed;
};

export interface IScannerCrowdswapChain {
  source_chain_id: number;
  poll_id: number;
  confirmation_txhash: string;
  block_number: number;
  timestamp: number;
  source_transaction_hash: string;
  tx_hash: string;
  gas_fee: string;
  fee_payer: string;
  operator: string;

  tx_hashLink?: string;
  tx_hashToDisplay?: string;
  timestampToDisplay?: string;
}

export enum CrosschainStatusToDisplay {
  sourceGatewayReceived = 'Initiating...',
  initiated = 'initiated',
  confirmed = 'confirmed',
  approved = 'approved',
  executed = 'fulfilled',
  canceled = 'refunded'
}

export enum CrosschainStatus {
  sourceGatewayReceived = 'sourceGatewayReceived',
  initiated = 'initiated',
  confirmed = 'confirmed',
  approved = 'approved',
  executed = 'executed',
  canceled = 'canceled'
}

export enum CrosschainStatusCode {
  sourceGatewayReceived = 0,
  initiated = 1,
  confirmed = 2,
  approved = 3,
  executed = 4,
  canceled = 5
}

export interface IScannerReqMdl {
  page: number;
  pageSize: number;
  createdFrom: string;
  createdTo: string;
  sort: 'acs' | 'desc';
  fromChainIds?: string;
  toChainIds?: string;
  status: CrosschainStatus;
  filter: string;
}

export interface IScannerRespMdl {
  data: ITxScanner[];
  totalCount: number;
}

export interface ISearchNetwork {
  title: string;
  chainId: string;
  checked: boolean;
  disable: boolean;
}
